<template>
    <div :style="{ backgroundColor: selectedColor }">
      <header class="absolute inset-x-0 top-0 z-50">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div class="flex lg:flex-1">
            <a href="#" class="-m-1.5 p-1.5">
              <span class="sr-only">Your Company</span>
              <img class="h-8 w-auto" src="@/assets/Logotype-Thomas.png" alt="" />
            </a>
          </div>
          <div class="hidden lg:flex lg:gap-x-12">
            <a v-for="item in navigation" :key="item.name" :href="item.href" :class="textColorClass">{{ item.name }}</a>
          </div>
          <div class="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" :class="textColorClass">Waiting...</a>
          </div>
        </nav>
      </header>
  
      <!-- Bouton Dropdown en haut à droite -->
      <div class="fixed top-4 right-4 z-50">
        <div class="relative inline-block text-left">
          <button @click="toggleDropdown" class="bg-white flex items-center rounded-full p-2">
            <PencilIcon class="h-5 w-5 text-black" aria-hidden="true" />
          </button>
  
          <div v-if="isDropdownOpen" class="absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div class="py-1">
              <template v-for="color in colors" :key="color.name">
                <button @click="selectColor(color)" class="flex items-center w-full px-4 py-2 text-sm">
                  <span class="block w-6 h-6 rounded-full mr-3" :style="{ backgroundColor: color.hex }"></span>
                  <span>{{ color.name }}</span>
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
  
      <div :style="{ backgroundImage: `linear-gradient(to bottom, ${selectedColor}20, white)` }" class="relative isolate overflow-hidden pt-14">
        <div class="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96" aria-hidden="true"></div>
        <div class="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 :class="[textColorClass, 'max-w-2xl text-4xl font-bold tracking-tight sm:text-6xl lg:col-span-2 xl:col-auto']">Great, you're here!</h1>
            <div class="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p :class="[textColorClass, 'text-lg leading-8']">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.</p>
              <div class="mt-10 flex items-center gap-x-6">
                <a href="#" :style="{ backgroundColor: selectedColor }" :class="[buttonTextColorClass, 'rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">Get started</a>
                <a href="#" :class="[textColorClass, 'text-sm font-semibold leading-6']">Learn more <span aria-hidden="true">→</span></a>
              </div>
            </div>
            <img src="@/assets/photo.jpg" alt="" class="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36" />
          </div>
        </div>
        <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"></div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue'
  import { PencilIcon } from '@heroicons/vue/24/outline'
  import tinycolor from 'tinycolor2'
  
  const navigation = [
    { name: '', href: '#' },
    { name: '', href: '#' },
    { name: '', href: '#' },
    { name: '', href: '#' },
  ]
  
  const isDropdownOpen = ref(false)
  const selectedColor = ref('#f43f5e')
  
  const colors = [
    { name: 'Indigo', hex: '#6366F1' },
    { name: 'Red', hex: '#EF4444' },
    { name: 'Green', hex: '#10B981' },
    { name: 'Yellow', hex: '#F59E0B' },
    { name: 'Blue', hex: '#3B82F6' },
    { name: 'Gray', hex: '#6b7280' },
    { name: 'Rose', hex: '#f43f5e' },
  ]
  
  function toggleDropdown() {
    isDropdownOpen.value = !isDropdownOpen.value
  }
  
  function selectColor(color) {
    selectedColor.value = color.hex
    localStorage.setItem('selectedColor', color.hex)
    isDropdownOpen.value = false
  }
  
  // Check if the selected color is very dark or not
  const isColorVeryDark = computed(() => {
    const brightness = tinycolor(selectedColor.value).getBrightness()
    return brightness < 50 // Adjust this value to your preference
  })
  
  // Dynamic classes based on the selected color
  const textColorClass = computed(() => {
    return isColorVeryDark.value ? 'text-white' : 'text-black'
  })
  
  const buttonTextColorClass = computed(() => {
    return isColorVeryDark.value ? 'text-white' : 'text-black'
  })
  
  // Load the color from localStorage if available
  onMounted(() => {
    const savedColor = localStorage.getItem('selectedColor')
    if (savedColor) {
      selectedColor.value = savedColor
    }
  })
  </script>
  