<template>
  <div>
    <router-view />
  </div>
</template>

<style scopted>
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 3px solid #ffffff;
}

.dark ::-webkit-scrollbar-track {
  background: #111827;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 3px solid #111827;
}
</style>